import React from 'react'
import { PayPalButton as PayPayPalButtonV2 } from 'react-paypal-button-v2'

const PayPalButton = ({
  createOrder,
  onApprove,
  onError,
  onCancel,
  disabled,
  style,
  className,
}) => {
  return (
    <div className={`btPayPalHolder ${className ? className : ''}`}>
      <div className={disabled ? 'paypal-button-custom-wrapper-disabled' : ''}>
        <PayPayPalButtonV2
          currency='BRL'
          shippingPreference='NO_SHIPPING'
          options={{
            clientId: process.env.NEXT_STATIC_PAYPAL_CLIENT_ID,
            currency: 'BRL',
          }}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          onCancel={onCancel}
          style={style}
        />
      </div>
    </div>
  )
}

export default PayPalButton
