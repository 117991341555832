import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import { useTranslation } from '../../../../services/translation'
import Price from '../../../Price'

export const Product = ({ model, formik, totalPriceWithFee, qtyDisabled }) => {
  const { t } = useTranslation()
  return (
    <div className='bottom-separator'>
      <p className='modelTitle'>{model.name}</p>
      <Row className='mb-3'>
        <Form.Group as={Col} xs={6} sm={6} md={4} lg={4} controlId='productQty'>
          <Form.Label>{t('modal.participa.qtyLabel')}</Form.Label>
          {!qtyDisabled ? (
            <>
              <Form.Control
                {...formik.getFieldProps('quotasQtd')}
                type='number'
                placeholder={t('forms.cotas')}
                isInvalid={formik.touched.quotasQtd && formik.errors.quotasQtd}
                isValid={formik.touched.quotasQtd && !formik.errors.quotasQtd}
                min='1'
              />
              <Form.Control.Feedback type='invalid'>
                {formik.touched.quotasQtd && formik.errors.quotasQtd
                  ? formik.errors.quotasQtd
                  : null}
              </Form.Control.Feedback>
            </>
          ) : (
            <p className='mb-0'>{formik.values.quotasQtd}</p>
          )}
        </Form.Group>
        {formik.values.quotasQtd > 0 && (
          <Col xs={6} sm={6} md={6} lg={6}>
            <Form.Label>{t('modal.participa.subtotal')}</Form.Label>
            <p className={`mb-0 ${!qtyDisabled ? 'subtotal-with-field' : ''}`}>
              {totalPriceWithFee === 0 ? (
                t('itemcard.free')
              ) : (
                <Price value={totalPriceWithFee} />
              )}
            </p>
          </Col>
        )}
      </Row>
    </div>
  )
}
