import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from '../../../../services/translation'
import { useAuth } from '../../../../contexts/auth'
import styles from './style.module.scss'
import { useLocales } from '../../../../contexts/locales'

export const SquareInfos = ({ model, totalPriceWithFee }) => {
  const { t } = useTranslation()
  const { currency } = useLocales()
  const { user } = useAuth()

  return (
    <>
      {model.status !== 'FINISHED' &&
        (!user || user.id !== model.organizer.id) && (
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{t('modal.participa.obs1')}</Tooltip>}
          >
            <div className={styles.squareInfo}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='40.921'
                height='50.3'
                viewBox='0 0 40.921 50.3'
              >
                <g id='sss' opacity='0.8'>
                  <path
                    id='Caminho_5'
                    data-name='Caminho 5'
                    d='M3.99,14.735,9,17.69v6.745L3.99,21.159Z'
                    transform='translate(3.333 12.31)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_6'
                    data-name='Caminho 6'
                    d='M7.805,17.045,12.816,20v6.745L7.805,23.533Z'
                    transform='translate(6.521 14.24)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_7'
                    data-name='Caminho 7'
                    d='M0,7.49l5.011,2.955V17.19L0,13.978Z'
                    transform='translate(0 6.257)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_8'
                    data-name='Caminho 8'
                    d='M7.805,12.25l5.011,2.955V21.95L7.805,18.674Z'
                    transform='translate(6.521 10.234)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_9'
                    data-name='Caminho 9'
                    d='M20.586,14.735,15.575,17.69v6.745l5.011-3.276Z'
                    transform='translate(13.012 12.31)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_10'
                    data-name='Caminho 10'
                    d='M16.736,17.045,11.725,20v6.745l5.011-3.212Z'
                    transform='translate(9.796 14.24)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_11'
                    data-name='Caminho 11'
                    d='M20.586,9.94l-5.011,2.891v6.745l5.011-3.212Z'
                    transform='translate(13.012 8.304)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_12'
                    data-name='Caminho 12'
                    d='M24.6,12.285,19.53,15.24v6.745L24.6,18.773Z'
                    transform='translate(16.316 10.263)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_13'
                    data-name='Caminho 13'
                    d='M3.99,19.81,9,22.7v6.745L3.99,26.234Z'
                    transform='translate(3.333 16.55)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_14'
                    data-name='Caminho 14'
                    d='M7.805,22.12l5.011,2.955V31.82L7.805,28.608Z'
                    transform='translate(6.521 18.48)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_15'
                    data-name='Caminho 15'
                    d='M0,17.36l5.011,2.955V27.06L0,23.848Z'
                    transform='translate(0 14.503)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_16'
                    data-name='Caminho 16'
                    d='M16.736,22.12l-5.011,2.955V31.82l5.011-3.212Z'
                    transform='translate(9.796 18.48)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_17'
                    data-name='Caminho 17'
                    d='M24.6,17.36,19.53,20.315V27.06L24.6,23.848Z'
                    transform='translate(16.316 14.503)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_18'
                    data-name='Caminho 18'
                    d='M24.6,7.49,19.53,10.445V17.19L24.6,13.978Z'
                    transform='translate(16.316 6.257)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_19'
                    data-name='Caminho 19'
                    d='M16.736,12.25l-5.011,2.955V21.95l5.011-3.276Z'
                    transform='translate(9.796 10.234)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_20'
                    data-name='Caminho 20'
                    d='M8.33,12.709l5.2-3.084,5.332,3.084-5.332,3.148Z'
                    transform='translate(6.959 8.041)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_21'
                    data-name='Caminho 21'
                    d='M4.445,10.428l5.2-3.148,5.332,3.148L9.648,13.576Z'
                    transform='translate(3.714 6.082)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_22'
                    data-name='Caminho 22'
                    d='M12.215,10.329l5.2-3.084,5.332,3.084-5.332,3.148Z'
                    transform='translate(10.205 6.053)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_23'
                    data-name='Caminho 23'
                    d='M8.33,8.048l5.2-3.148,5.332,3.148L13.533,11.2Z'
                    transform='translate(6.959 4.094)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_24'
                    data-name='Caminho 24'
                    d='M.42,7.838l5.2-3.148,5.332,3.148L5.623,10.986Z'
                    transform='translate(0.351 3.918)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_25'
                    data-name='Caminho 25'
                    d='M4.3,5.458l5.2-3.148L14.84,5.458,9.508,8.606Z'
                    transform='translate(3.597 1.93)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_26'
                    data-name='Caminho 26'
                    d='M16.065,8.048,21.333,4.9,26.6,8.048,21.333,11.2Z'
                    transform='translate(13.421 4.094)'
                    fill='#009291'
                  />
                  <path
                    id='Caminho_27'
                    data-name='Caminho 27'
                    d='M8.155,3.148,13.423,0,18.69,3.148,13.423,6.231Z'
                    transform='translate(6.813)'
                    fill='#009291'
                  />
                </g>
              </svg>
              <p className='m-0 p-0 mt-1'>
                {t('modal.participa.arteconceitual')}
              </p>
            </div>
          </OverlayTrigger>
        )}
      <OverlayTrigger
        placement='bottom'
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{t('alerta.vendastl')}</Tooltip>}
      >
        <div className={styles.squareInfo}>
          <svg
            id='_360-degrees'
            data-name='360-degrees'
            xmlns='http://www.w3.org/2000/svg'
            width='53'
            height='53'
            viewBox='0 0 53 53'
          >
            <path
              id='Caminho_1'
              data-name='Caminho 1'
              d='M176.7,148.416a1.035,1.035,0,0,0-.518-.9l-9.316-5.373a1.042,1.042,0,0,0-1.034,0l-9.316,5.373a1.035,1.035,0,0,0-.518.9V159.2a1.024,1.024,0,0,0,.523.9l9.335,5.389a1.035,1.035,0,0,0,1.037,0l9.293-5.392a1.041,1.041,0,0,0,.516-.9V148.416Zm-2.07,8.989-7.246-4.205v-8.364l7.246,4.179Zm-9.316-12.569v8.39l-7.246,4.184v-8.4Zm1.058,18.563-7.269-4.2,7.269-4.2,7.231,4.2Z'
              transform='translate(-139.852 -127.309)'
              fill='#009291'
            />
            <path
              id='Caminho_2'
              data-name='Caminho 2'
              d='M40.893,12.1C38.25,5.6,33.517,0,26.5,0c-7,0-11.745,5.6-14.4,12.108C5.6,14.751,0,19.483,0,26.5c0,7,5.6,11.745,12.108,14.4C14.751,47.4,19.483,53,26.5,53a12.245,12.245,0,0,0,5.215-1.153,1.035,1.035,0,1,0-.875-1.876,10.192,10.192,0,0,1-4.34.959c-6.05,0-9.713-4.987-11.7-9.06a39.524,39.524,0,0,0,11.7,1.814,39.518,39.518,0,0,0,11.7-1.821c-.365.751-.754,1.465-1.165,2.135A1.035,1.035,0,0,0,38.8,45.08a28.866,28.866,0,0,0,2.1-4.188C47.4,38.249,53,33.517,53,26.5S47.4,14.754,40.893,12.1ZM26.5,2.07c6.05,0,9.713,4.987,11.7,9.06A39.524,39.524,0,0,0,26.5,9.316a39.517,39.517,0,0,0-11.7,1.821C16.783,7.062,20.45,2.07,26.5,2.07ZM2.07,26.5c0-6.05,4.987-9.713,9.06-11.7A39.524,39.524,0,0,0,9.316,26.5a39.517,39.517,0,0,0,1.821,11.7C7.062,36.218,2.07,32.551,2.07,26.5ZM13.707,39.3a37.786,37.786,0,0,1-2.32-12.8A37.177,37.177,0,0,1,13.7,13.707a37.786,37.786,0,0,1,12.8-2.32A37.176,37.176,0,0,1,39.293,13.7a37.782,37.782,0,0,1,2.32,12.8A37.177,37.177,0,0,1,39.3,39.294a37.787,37.787,0,0,1-12.8,2.32A37.177,37.177,0,0,1,13.707,39.3ZM41.87,38.2a39.525,39.525,0,0,0,1.814-11.7,39.516,39.516,0,0,0-1.821-11.7c4.076,1.985,9.067,5.651,9.067,11.7S45.943,36.214,41.87,38.2Z'
              fill='#009291'
            />
            <circle
              id='Elipse_1'
              data-name='Elipse 1'
              cx='1'
              cy='1'
              r='1'
              transform='translate(34 47)'
              fill='#009291'
            />
          </svg>
          <p className='m-0 p-0 mt-1'>{t('modal.participa.itemdigital')}</p>
        </div>
      </OverlayTrigger>
      {totalPriceWithFee !== 0 && currency !== 'BRL' && (
        <OverlayTrigger
          placement='bottom'
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>{t('dollarObservation')}</Tooltip>}
        >
          <div className={styles.squareInfo}>
            <svg
              id='changing-money'
              xmlns='http://www.w3.org/2000/svg'
              width='45.25'
              height='45.25'
              viewBox='0 0 37.25 37.25'
            >
              <g id='Grupo_5' data-name='Grupo 5'>
                <path
                  id='Caminho_28'
                  data-name='Caminho 28'
                  d='M56.955,69.048A12.094,12.094,0,1,0,69.049,56.954,12.108,12.108,0,0,0,56.955,69.048Zm22.467,0A10.374,10.374,0,1,1,69.049,58.674,10.385,10.385,0,0,1,79.422,69.048Z'
                  transform='translate(-50.424 -50.423)'
                  fill='#009291'
                />
                <path
                  id='Caminho_29'
                  data-name='Caminho 29'
                  d='M132.5,103.325v1.347a3.667,3.667,0,0,0-1.361.632,3.023,3.023,0,0,0-1.383,3.147,2.909,2.909,0,0,0,2.561,2.046c2.962.415,3.444.857,3.317,1.895a1.4,1.4,0,0,1-.944,1.176,4.35,4.35,0,0,1-4.114-1.009l-1.077,1.341a7.19,7.19,0,0,0,3,1.451v1.527h1.72v-1.437a4.262,4.262,0,0,0,1.117-.28,3.129,3.129,0,0,0,2-2.56,2.756,2.756,0,0,0-1.5-2.965,10.144,10.144,0,0,0-3.282-.841,1.235,1.235,0,0,1-1.132-.765c-.107-.42.169-.921.737-1.34a2.179,2.179,0,0,1,2.167-.2,4.766,4.766,0,0,1,1.268.706l.551-.66.553-.659a6.348,6.348,0,0,0-1.731-.984,5.209,5.209,0,0,0-.748-.218v-1.348Z'
                  transform='translate(-114.645 -91.477)'
                  fill='#009291'
                />
                <path
                  id='Caminho_30'
                  data-name='Caminho 30'
                  d='M18.625,1.72A16.781,16.781,0,0,1,28.75,5.085H26.488v1.72h5.535V1.271H30.3V4.114A18.626,18.626,0,0,0,0,18.625H1.72A16.924,16.924,0,0,1,18.625,1.72Z'
                  fill='#009291'
                />
                <path
                  id='Caminho_31'
                  data-name='Caminho 31'
                  d='M77.568,162.421a16.908,16.908,0,0,1-26.8,13.712h2.246v-1.72H47.483v5.535H49.2V177.1a18.626,18.626,0,0,0,30.085-14.683Z'
                  transform='translate(-42.038 -143.796)'
                  fill='#009291'
                />
              </g>
            </svg>
            <p className='m-0 p-0 mt-1'>{t('modal.participa.conversao')}</p>
          </div>
        </OverlayTrigger>
      )}
    </>
  )
}
