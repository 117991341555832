import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { QueryHookResult } from 'react-apollo-hooks'
import { Transaction } from '../entities/Transaction'

const GET_USER_TRANSACTION = gql`
  query getUserTransaction($gatewayId: ID!) {
    getUserTransaction(gatewayId: $gatewayId) {
      id
      status
    }
  }
`

interface GetUserTransactionData {
  getUserTransaction: Transaction | null
}

interface GetUserTransactionVars {
  gatewayId: string
}

const useGetUserTransaction = (
  options?: QueryHookOptions<GetUserTransactionData, GetUserTransactionVars>
): QueryHookResult<GetUserTransactionData, GetUserTransactionVars> => {
  return useQuery(GET_USER_TRANSACTION, options)
}

export default useGetUserTransaction
