import { useEffect, useState, useRef } from 'react'
import { Alert, Spinner, Row, Col, Overlay, Tooltip } from 'react-bootstrap'
import ModalConfirm2 from '../../../ModalConfirm2'
import useGetUserTransaction from '../../../../services/GetUserTransaction'
import { TransactionStatus } from '../../../../entities/TransactionStatus'
import { useTranslation } from '../../../../services/translation'

export const PixQrcode = ({ pixInfo, onClose, onSuccessPayment }) => {
  const { t } = useTranslation()
  const [showCode, setShowCode] = useState(false)
  const [showtooltip, setShowtooltip] = useState(false)
  const target = useRef(null)

  const handleCopyQrcode = async () => {
    try {
      await navigator.clipboard.writeText(pixInfo.qrcode)
      setShowtooltip(!showtooltip)
      setTimeout(() => setShowtooltip(false), 1000)
    } catch (error) {
      setShowCode(true)
    }
  }
  const [generalError, setGeneralError] = useState()
  const { refetch } = useGetUserTransaction({
    skip: true,
  })
  const checkPayment = async () => {
    try {
      const res = await refetch({
        gatewayId: pixInfo.gatewayId,
      })
      const { status } = res.data.getUserTransaction || {}
      if (status === TransactionStatus.CREATED) {
        setTimeout(() => {
          checkPayment()
        }, 10_000)
        return
      }
      if (status === TransactionStatus.COMPLETED) {
        onSuccessPayment()
        return
      }
      throw new Error('Transaction canceled or not found.')
    } catch (error) {
      console.error(error)
      setGeneralError(t('modal.participa.pixFailed'))
    }
  }
  useEffect(() => {
    setTimeout(() => {
      checkPayment()
    }, 15_000)
  }, [])
  return (
    <ModalConfirm2 show handleClose={onClose} size='lg' title='Pagar com PIX'>
      {!generalError ? (
        <>
          <Row className='py-3'>
            <Col xs={12} sm={12} md={12} lg={6} className='px-5 text-center'>
              <div className='d-flex flex-column pixReverseMob'>
                <div>
                  {t('modal.participa.leiacomapp')}
                  <img src={pixInfo.qrcodeImage} />
                </div>
                <p>{t('modal.participa.ou')}</p>
                <button
                  ref={target}
                  onClick={handleCopyQrcode}
                  className='btLinkStyle mb-4'
                >
                  {t('modal.participa.btcopiar')}
                </button>
                <Overlay
                  target={target.current}
                  show={showtooltip}
                  placement='top'
                >
                  {props => (
                    <Tooltip id='overlay-example' {...props}>
                      {t('modal.participa.feedbackcopiado')}
                    </Tooltip>
                  )}
                </Overlay>
                {showCode && <p>{pixInfo.qrcode}</p>}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className='border-start px-4'>
              <b>{t('modal.participa.titpix')}</b>
              <p>{t('modal.participa.subtitpix')}</p>
              <div className='my-4'>
                <Spinner animation='border' role='status'>
                  <span className='sr-only'>
                    {t('modal.participa.aguardandopag')}
                  </span>
                </Spinner>{' '}
                {t('modal.participa.aguardando')}
              </div>
              <p className='small mt-2'>
                {t('modal.participa.direcionamento')}
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <Alert variant='danger'>{generalError}</Alert>
      )}
    </ModalConfirm2>
  )
}
