import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Alert, Spinner } from 'react-bootstrap'
import ButtonComp from '../Button'
import { useTranslation } from '../../services/translation'
import ModalConfirm2 from '../ModalConfirm2'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import Router from 'next/router'
import { useBalance } from '../../contexts/balance'
import PayPalButton from '../PayPalButton'
import { initializeApollo } from '../../services/apolloClient'
import Price from '../Price'
import { useCreatePurchase } from '../../services/createPurchase'
import { SquareInfos } from './components/SquareInfos'
import { Product } from './components/Product'
import { TransWithComponents } from '../TransWithComponents'
import { PixQrcode } from './components/PixQrcode'

const DO_CAPTURE_ORDER = gql`
  mutation ($orderId: String!, $payerId: String!) {
    capturePayPalOrder(orderId: $orderId, payerId: $payerId) {
      transaction {
        status
      }
      user {
        balance
        balancePending
        balanceChargeback
        balanceCollection
      }
    }
  }
`

const GET_USER_BALANCE = gql`
  query getUserInfo {
    getUser {
      balance
      balancePending
      balanceChargeback
      balanceCollection
    }
  }
`

const DO_CANCEL_ORDER = gql`
  mutation ($orderId: String!) {
    cancelPayPalOrder(orderId: $orderId)
  }
`

const paymentMethods = [
  {
    value: 'PAYPAL',
    label: 'PayPal',
    icon: '/assets/img/paypal-icon.png',
  },
  {
    value: 'PIX',
    label: 'Pix',
    icon: '/assets/img/pix-icon.png',
  },
]

function ModalCheckout({
  show,
  handleClose,
  model,
  onRefreshModel,
  quotaPriceWithFee,
  changePurchase,
  qtyDisabled,
}) {
  const [pixInfo, setPixInfo] = useState()
  const [generalErrors, setGeneralErrors] = useState('')
  const [generalWarning, setGeneralWarning] = useState('')
  const [loadingPayPal, setLoadingPayPal] = useState(false)
  const { t } = useTranslation()
  const { setBalance, balance } = useBalance()
  useEffect(() => {
    if (balance != null) {
      formik.setFieldValue('useCredits', balance > 0)
    }
  }, [balance])
  const [createPurchase] = useCreatePurchase()
  const { refetch } = useQuery(GET_USER_BALANCE, {
    fetchPolicy: 'network-only',
    skip: true,
  })
  const [coupons] = useState([])

  const refreshValues = async () => {
    if (generalWarning === 'userBalance') {
      await updateBalance()
    } else {
      if (onRefreshModel) await onRefreshModel()
    }
    setGeneralWarning('')
    setGeneralErrors('')
  }

  const updateBalance = async () => {
    const newBalance = await refetch()
    if (newBalance && newBalance.data) {
      const {
        balance: balanceData,
        balancePending,
        balanceChargeback,
        balanceCollection,
      } = newBalance.data.getUser
      setBalance(
        balanceData,
        balancePending,
        balanceChargeback,
        balanceCollection
      )
    }
  }

  const handleSuccessPurchase = async () => {
    formik.resetForm()
    await updateBalance()
    Router.push(
      '/successfullypurchase?transaction=COMPLETED&status=' + model.status,
      'successfullypurchase'
    )
  }

  const formik = useFormik({
    initialValues: {
      quotasQtd: 1,
      useCredits: '',
      method: paymentMethods[0],
    },
    validationSchema: Yup.object({
      quotasQtd: Yup.number()
        .moreThan(0, ({ more }) => t('forms.cotasmin', { min: more }))
        .required(t('forms.cotasvali')),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (showPayPal) {
        return setSubmitting(false)
      }
      try {
        setGeneralErrors('')
        setGeneralWarning('')
        const result = await callCreatePurchase(values)
        if (
          !result.data.createPurchase.completed &&
          hasCreditsEnough &&
          formik.values.useCredits
        ) {
          setGeneralErrors(t('modal.participa.failed'))
        } else {
          if (!result.data.createPurchase.completed) {
            setPixInfo({
              gatewayId: result.data.createPurchase.gatewayId,
              qrcode: result.data.createPurchase.qrcode,
              qrcodeImage: result.data.createPurchase.qrcodeImage,
            })
          } else {
            await handleSuccessPurchase()
          }
        }
      } catch (error) {
        handleError(error)
      }
      setSubmitting(false)
    },
  })

  const callCreatePurchase = async values => {
    const defaultVariables = {
      item: {
        type: 'MODEL_QUOTA',
        modelId: model.id,
        quotasQtd: parseInt(values.quotasQtd),
      },
      useCredits: values.useCredits,
      method: values.method.value,
      userBalance: parseFloat(balance),
      totalValue: quotaPriceWithFee * values.quotasQtd,
    }
    return await createPurchase({
      variables: changePurchase
        ? changePurchase(defaultVariables, values)
        : defaultVariables,
    })
  }

  const handleError = error => {
    const { networkError, graphQLErrors } = error
    let gError = ''
    if (networkError || (!networkError && !graphQLErrors))
      gError = t('modal.participa.failed')
    else {
      if (graphQLErrors.length) {
        graphQLErrors.map(({ state }) => {
          if (!state) return (gError = t('modal.participa.failed'))
          Object.entries(state).forEach(entry => {
            if (entry[0] === 'userBalance' || entry[0] === 'totalValue') {
              return setGeneralWarning(entry[0])
            }
            const key = entry[0].replace('item.', '')
            if (!['quotasQtd', 'useCredits', 'method'].includes(key))
              gError = entry[1][0]
            else formik.setFieldError(key, entry[1][0])
          })
        })
      }
    }
    setGeneralErrors(gError)
  }

  const handleMethodChange = e => {
    const methodFound = paymentMethods.find(
      method => method.value === e.target.value
    )
    formik.setFieldValue('method', methodFound)
  }

  const handleClosePix = () => {
    setPixInfo(undefined)
  }

  const totalPriceWithFee = quotaPriceWithFee * formik.values.quotasQtd
  const hasCreditsEnough = balance >= totalPriceWithFee
  const showPayPal =
    formik.values.method.value === 'PAYPAL' &&
    totalPriceWithFee > 0 &&
    (formik.values.useCredits === false || !hasCreditsEnough)
  const buttonLabelKey =
    quotaPriceWithFee === 0
      ? 'btcomprarFree'
      : formik.values.useCredits === false || !hasCreditsEnough
      ? 'btcomprar'
      : 'btcomprarCredits'
  if (pixInfo) {
    return (
      <PixQrcode
        pixInfo={pixInfo}
        onClose={handleClosePix}
        onSuccessPayment={handleSuccessPurchase}
      />
    )
  }
  return (
    <ModalConfirm2
      show={show}
      handleClose={handleClose}
      title={t('modal.participa.tit')}
      className='modalBuy'
      size='lg'
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} className='col-product'>
            <div className='d-flex justify-content-start bottom-separator'>
              <SquareInfos
                model={model}
                totalPriceWithFee={totalPriceWithFee}
              />
            </div>
            <Product
              model={model}
              formik={formik}
              totalPriceWithFee={totalPriceWithFee}
              qtyDisabled={qtyDisabled}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={`col-payment ${totalPriceWithFee !== 0 ? 'mb-3' : ''}`}
          >
            {totalPriceWithFee > 0 && (
              <div className='bottom-separator mb-lg-3'>
                {balance > 0 && (
                  <div className='mb-2'>
                    <Form.Check
                      type='checkbox'
                      id='check-credits'
                      className='custom-control custom-checkbox'
                    >
                      <Form.Check.Input
                        {...formik.getFieldProps('useCredits')}
                        checked={formik.values.useCredits}
                        className='custom-control-input'
                        type='checkbox'
                      />
                      <Form.Check.Label
                        className='custom-control-label'
                        htmlFor='check-credits'
                      >
                        {t('modal.participa.useCredits')}{' '}
                        <Price value={balance} />
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                )}
                {paymentMethods.length > 1 &&
                  (formik.values.useCredits === false || !hasCreditsEnough) && (
                    <>
                      <p className='mb-0'>
                        {t('modal.participa.paymentMethod')}
                      </p>
                      <div className='RadioPayment'>
                        {paymentMethods.map((method, index) => (
                          <Form.Group key={index}>
                            <Form.Check type='radio' name='selconta'>
                              <Form.Check.Label
                                htmlFor={`selconta-${method.value}`}
                              >
                                <Form.Check.Input
                                  {...formik.getFieldProps('method')}
                                  onChange={handleMethodChange}
                                  type='radio'
                                  value={method.value}
                                  checked={
                                    formik.values.method.value === method.value
                                  }
                                  id={`selconta-${method.value}`}
                                />
                                <img src={method.icon} alt={method.label} />
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        ))}
                      </div>
                    </>
                  )}
              </div>
            )}
            {formik.values.quotasQtd > 0 && (
              <>
                <p className='summary-title mb-2'>
                  {t('modal.participa.summaryTitle')}
                </p>
                <ul className='checkoutList'>
                  {coupons.length > 0 && (
                    <>
                      <li>
                        {t('modal.participa.subtotal')}{' '}
                        <span className='checkoutValue'>
                          <Price value={totalPriceWithFee} />
                        </span>
                      </li>
                      <li className='listCredit'>
                        Cupom XXXXX{' '}
                        <span className='checkoutValue'>
                          - <Price value={10.1} />
                        </span>
                      </li>
                    </>
                  )}
                  <li className='listTotal'>
                    {t('modal.participa.totalWithFee')}{' '}
                    <span className='checkoutValue'>
                      {totalPriceWithFee === 0 ? (
                        t('itemcard.free')
                      ) : (
                        <Price value={totalPriceWithFee} />
                      )}
                    </span>
                  </li>
                </ul>
              </>
            )}
            {formik.values.useCredits && balance > 0 && !hasCreditsEnough && (
              <p className='text-center mixed-payment'>
                <TransWithComponents
                  i18nKey='translation:modal.participa.mixedPayment'
                  components={[
                    <Price value={balance} key={0} />,
                    <Price value={totalPriceWithFee - balance} key={1} />,
                  ]}
                  values={{
                    method: formik.values.method.label,
                  }}
                />
              </p>
            )}
            {generalErrors && <Alert variant='danger'>{generalErrors}</Alert>}
            {generalWarning && (
              <Alert variant='warning'>
                {t(`modal.participa.valuesNotMatch.${generalWarning}`)}
                <Button onClick={refreshValues} className='mt-2'>
                  {t('modal.participa.valuesNotMatch.button')}
                </Button>
              </Alert>
            )}
            <div className='checkout-buttons-wrap d-flex justify-content-center mb-3'>
              <PayPalButton
                className={`react-paypal-modal-participa ${
                  !showPayPal ? 'd-none' : ''
                }`}
                style={{
                  layout: 'horizontal',
                  tagline: false,
                  height: 54,
                }}
                disabled={loadingPayPal}
                createOrder={async () => {
                  setGeneralErrors('')
                  setGeneralWarning('')
                  if (loadingPayPal) return null
                  try {
                    const errors = formik.validateForm()
                    if (!Object.entries(errors).length) {
                      const result = await callCreatePurchase(formik.values)
                      return result.data.createPurchase.gatewayId
                    }
                  } catch (error) {
                    handleError(error)
                  }
                }}
                onApprove={(data, actions) => {
                  setLoadingPayPal(true)
                  const apolloClient = initializeApollo()
                  return apolloClient
                    .mutate({
                      mutation: DO_CAPTURE_ORDER,
                      variables: {
                        orderId: data.orderID,
                        payerId: data.payerID,
                      },
                    })
                    .then(result => {
                      if (
                        result.data.capturePayPalOrder.transaction.status ===
                        'COMPLETED'
                      ) {
                        Router.push(
                          '/successfullypurchase?transaction=COMPLETED&status=' +
                            model.status,
                          'successfullypurchase'
                        )
                      } else {
                        Router.push(
                          '/successfullypurchase?transaction=0',
                          'successfullypurchase'
                        )
                      }
                      const {
                        balance,
                        balancePending,
                        balanceChargeback,
                        balanceCollection,
                      } = result.data.capturePayPalOrder.user
                      setBalance(
                        balance,
                        balancePending,
                        balanceChargeback,
                        balanceCollection
                      )
                      formik.resetForm()
                    })
                    .catch(({ graphQLErrors }) => {
                      let gError = t('compracota.errorVerify')
                      if (graphQLErrors.length) {
                        graphQLErrors.map(({ state }) => {
                          Object.entries(state).forEach(entry => {
                            if (entry[0] === '') {
                              if (entry[1][0] === 'INSTRUMENT_DECLINED') {
                                return actions.restart()
                              }
                              gError = entry[1][0]
                            }
                          })
                        })
                      }
                      setGeneralErrors(gError)
                    })
                    .finally(() => setLoadingPayPal(false))
                }}
                onError={() => {
                  setLoadingPayPal(false)
                  setGeneralErrors(t('compracota.error'))
                }}
                onCancel={data => {
                  const apolloClient = initializeApollo()
                  return apolloClient
                    .mutate({
                      mutation: DO_CANCEL_ORDER,
                      variables: {
                        orderId: data.orderID,
                      },
                    })
                    .catch(() => {
                      setGeneralErrors(t('compracota.errorCancel'))
                    })
                    .finally(() => setLoadingPayPal(false))
                }}
              />
              <ButtonComp
                className={`btn-paralel ${showPayPal ? 'd-none' : ''}`}
                label={t(`itemcard.${buttonLabelKey}`)}
                loading={formik.isValidating || formik.isSubmitting}
                disabled={formik.isValidating || formik.isSubmitting}
              />
            </div>
            {loadingPayPal && (
              <div className='text-center'>
                <Spinner animation='border' />
                <p>{t('compracota.loading')}</p>
              </div>
            )}
          </Col>
        </Row>
      </Form>
      {totalPriceWithFee !== 0 && (
        <>
          <span className='alertaestorno'>{t('menubottom.aviso')}</span>
          {model.status !== 'FINISHED' && (
            <span className='alertaestorno'>
              {' '}
              {t('modal.participa.chargeback')}
            </span>
          )}
        </>
      )}
    </ModalConfirm2>
  )
}

export default ModalCheckout
