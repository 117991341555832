import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const CREATE_PURCHASE = gql`
  mutation createPurchase(
    $item: PurchaseItemInput!
    $useCredits: Boolean!
    $method: PaymentMethod
    $userBalance: Float!
    $totalValue: Float!
  ) {
    createPurchase(
      item: $item
      useCredits: $useCredits
      method: $method
      userBalance: $userBalance
      totalValue: $totalValue
    ) {
      completed
      gatewayId
      qrcode
      qrcodeImage
    }
  }
`

export const useCreatePurchase = () => {
  return useMutation(CREATE_PURCHASE)
}
